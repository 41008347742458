import { RestService } from './rest.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class WpRestService extends RestService {

  private base = '/wp';

  public get<T>(endpoint: string, queryParams?: { [key: string]: string }, headers?: HttpHeaders, silent: boolean = false): Observable<T> {
    endpoint = `${this.base}${endpoint}`;
    return super.dirtyGet(endpoint, queryParams, headers, silent);
  }
}
