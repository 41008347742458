import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { GeneralSettingsResolver } from './shared/resolvers/general-settings.resolver';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DndDirective } from './shared/directives/dnd.directive';
import { TeamDataResolver } from './shared/resolvers/team-data.resolver';
import { DashboardSettingsResolver } from './shared/resolvers/dashboard-settings.resolver';
import { LoginActivate } from './shared/activates/login.activate';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { HomeResolver } from './shared/resolvers/home.resolver';
import { CharityResolver } from './shared/resolvers/charity.resolver';
import { DigitalworkoutResolver } from './shared/resolvers/digitalworkout.resolver';
import { StoryResolver } from './shared/resolvers/story.resolver';
import { GlobalStatsResolver } from './shared/resolvers/global-stats.resolver';
import { MilestonesResolver } from './shared/resolvers/milestones.resolver';
import { TeamRunningResolver } from './shared/resolvers/team-running.resolver';
import { TeamRankingResolver } from './shared/resolvers/team-ranking.resolver';

@NgModule({
  declarations: [
    AppComponent, DndDirective
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NgxIntlTelInputModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    TransferHttpCacheModule,
  ],
  providers: [
    GeneralSettingsResolver,
    TeamDataResolver,
    DashboardSettingsResolver,
    HomeResolver,
    LoginActivate,
    CharityResolver,
    DigitalworkoutResolver,
    StoryResolver,
    GlobalStatsResolver,
    MilestonesResolver,
    TeamRunningResolver,
    TeamRankingResolver
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
