import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { StatsService } from '../services/stats.service';

@Injectable()
export class GlobalStatsResolver implements Resolve<Promise<any>> {

  constructor(private statsService: StatsService) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    try {
      return {
        participants: await this.statsService.getGlobalUsers().toPromise(),
        stats: await this.statsService.getGlobalStats().toPromise(),
        teams: await this.statsService.getGlobalTeams().toPromise(),
      };
    } catch (e) {
      return null;
    }
  }
}