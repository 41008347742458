import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Allianz Word Run';
  isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.showApp();
  }

  private showApp(): void {
    if (this.isBrowser) {
      const element: HTMLElement | null = this.document.getElementById('app-wrapper');
      if (element) {
        element.classList.remove('invisible');
      }
    }
  }
}
