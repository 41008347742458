import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { WpRestService } from '../services/wp-rest.service';

@Injectable()
export class CharityResolver implements Resolve<Promise<any>> {
  constructor(private wpRestService: WpRestService) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    try {
      return await this.wpRestService.get('/charity').toPromise();
    } catch (e) {
      return null;
    }
  }
}
