import { Injectable } from '@angular/core';
import { WpRestService } from './wp-rest.service';
import { Observable, of } from 'rxjs';
import { GeneralSettings } from '../models/general-settings.model';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralSettingsService {

  constructor(
    private wpRestService: WpRestService,
    private platformService: PlatformService,
  ) {}

  get(): Observable<GeneralSettings> {
    if (this.platformService.isBrowser()) {
      return this.wpRestService.get('/general-settings');
    }
    return of();
  }
}
