import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { WpRestService } from '../services/wp-rest.service';

@Injectable()
export class MilestonesResolver implements Resolve<Promise<any>> {
  constructor(private wpRestService: WpRestService) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    try {
      return await this.wpRestService.get('/milestones').toPromise();
    } catch (e) {
      return null;
    }
  }
}
