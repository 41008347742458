import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TeamRunning} from '../../../../../shared/models/team-running.model';
import { TeamRanking} from '../../../../../shared/models/team-ranking.model';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class LeaderboardService {

    constructor(private restService: RestService) {}

    getLeaderboardRanking(): Observable<TeamRanking[]> {
        return this.restService.get('/page/teams/stats/ranking');
    }

    getLeaderboardRunning(): Observable<TeamRunning[]> {
        return this.restService.get('/page/teams/stats/running');
    }

}
