import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CookieServerService } from './cookie-server.service';
import { PlatformService } from './platform.service';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn = false;
  public checked = true;
  private sessionUuid: string | undefined;

  constructor(@Inject(DOCUMENT) private document: Document,
              private restService: RestService,
              private platformService: PlatformService) {
    this.checkLoginStatus();
  }

  public async checkLoginStatus(): Promise<boolean> {
    return await this.restService.post('/isLoggedIn', {uuid: this.sessionUuid}).toPromise().then(val => {
      this.loggedIn = val as boolean;
      if (this.loggedIn && this.sessionUuid) {
        this.sessionUuid = '';
      }
      this.checked = true;
      return this.loggedIn;
    });
  }

  public login(sessionUuid?: string): void {
    this.sessionUuid = sessionUuid;
    this.loggedIn = true;
  }

  public logout(): void {
    this.sessionUuid = '';
    this.loggedIn = false;
  }

  public isLoggedIn(): boolean {
    return this.loggedIn;
  }
}
