import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { WpRestService } from '../services/wp-rest.service';

@Injectable()
export class DashboardSettingsResolver implements Resolve<Promise<any>> {
  constructor(private wpService: WpRestService) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    try {
      return await this.wpService.get('/dashboard-settings').toPromise();
    } catch (e) {
      return null;
    }
  }
}
