import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { GeneralSettingsService } from '../services/general-settings.service';

@Injectable()
export class GeneralSettingsResolver implements Resolve<Promise<any>> {
  constructor(private generalSettingsService: GeneralSettingsService) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    try {
      return await this.generalSettingsService.get().toPromise();
    } catch (e) {
      return null;
    }
  }
}
