import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalStats, GlobalTeamPerDay, TeamsStatsPerDay, UserStatsPerDay} from '../../../../../shared/models/stats.model';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class StatsService {

    constructor(private restService: RestService) {}

    getGlobalStats(): Observable<GlobalStats> {
        return this.restService.get('/stats/global/users');
    }

    getGlobalUsers(): Observable<number> {
        return this.restService.get('/user/participants/count');
    }

    getGlobalTeams(): Observable<number> {
        return this.restService.get('/page/teams/count');
    }

    getTeamStats(teamId: number): Observable<TeamsStatsPerDay[]> {
        return this.restService.get(`/stats/team/${teamId}/per-day`);
    }

    getUserStats(userId: number): Observable<UserStatsPerDay[]> {
        return this.restService.get(`/stats/user/${userId}/per-day`);
    }

    getGlobalTeamStats(): Observable<GlobalTeamPerDay[]> {
        return this.restService.get('/stats/global/team-avg/per-day');
    }
}
