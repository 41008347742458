import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { RestService } from '../services/rest.service';

@Injectable()
export class TeamDataResolver implements Resolve<Promise<any>> {
  constructor(private restService: RestService) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    try {
      return await this.restService.get('/teams').toPromise();
    } catch (e) {
      return null;
    }
  }
}
