import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { RestService } from '../services/rest.service';

@Injectable()
export class StoryResolver implements Resolve<Promise<any>> {
  constructor(private restService: RestService) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    try {
         const storySlug = route.params.slug;
         return await this.restService.get(`/wp/storyposts/${storySlug}`).toPromise();
    } catch (e) {
        return null;
    }
  }
}
