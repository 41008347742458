import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
@Injectable({
  providedIn: 'root',
})
export class PlatformService {

  private readonly browser: boolean;
  private readonly server: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: string) {
    this.browser = isPlatformBrowser(platformId);
    this.server = isPlatformServer(platformId);
  }
  public isBrowser(): boolean {
    return this.browser;
  }
  public isServer(): boolean {
    return this.server;
  }
  public showApp(): void {
    if (this.isBrowser()) {
      document.getElementById('app-wrapper')?.classList.remove('invisible');
    }
  }
}
