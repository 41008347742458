// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from '../app/shared/models/environment.model';

export const environment: Environment = {
  production: false,
  baseurl: 'http://allianzworldrun.decid.io/backend',
  websiteUrl: 'http://localhost:4200',
  // baseurl: 'https://allianz.scoopandspoon.at/backend',
  runtasticServer: 'https://accounts.runtastic.com',
  oauthTokenRedirectURI: 'https://allianz.scoopandspoon.at/oauth/callback',
  oauthCodeURLPath: '/v1/as/authorization.oauth2' +
    '?client_id=papi_allianz' +
    '&response_type=code' +
    '&scope=pa_activity pa_identity openid' +
    '&pfidpadapterid=refid' +
    '&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Foauth%2Fcallback'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
