import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { LeaderboardService } from '../services/leaderboard.service';

@Injectable()
export class TeamRunningResolver implements Resolve<Promise<any>> {

  constructor(private leaderboardService: LeaderboardService) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    try {
      return await this.leaderboardService.getLeaderboardRunning().toPromise();
    } catch (e) {
      return null;
    }
  }
}
